import { createSelector } from 'reselect';
import constants from 'appConstants';
import { groupBy, keyBy, max, min } from 'lodash-es';
import {
    averagesValues,
    mapData,
    filterYards,
    sortYards,
    actionsValues,
    HIVE_CATEGORY_COLORS,
    DEFAULT_HIVE_CONFIGURATION,
} from '../utils';
import { getBhomes, getGridData, getYards } from '../../Dashboard/selectors';

export const getWorkspaceData = state => state.workspace.data;
export const getIsWorkspaceFetched = state => state.workspace.isFetched;
export const getWorkspaceSearch = state => state.workspace.search;
export const getWorkspaceFilter = state => state.workspace.filter;
export const getWorkspaceSort = state => state.workspace.sort;
export const getWorkspaceActions = state => state.workspace.workspaceActions;
export const getInspectedYard = state => state.workspace.inspectedYard;
export const getYardEvents = state => state.workspace.yardEvents;
export const getWorkspaceRanches = state => state.workspace.workspaceRanches;

export const getPreparedWorkspaceData = data => {
    const groupedByDate = groupBy(data, 'createdAt');
    const todaysDataKey = max(Object.keys(groupedByDate));
    const yesterdaysDataKey = min(Object.keys(groupedByDate));
    const todaysGroupedData = groupBy(groupedByDate[todaysDataKey], 'yardId');
    const todaysRanchesGroupedData = groupBy(groupedByDate[todaysDataKey], 'ranchId');
    const yesterdaysGroupedData = groupBy(groupedByDate[yesterdaysDataKey], 'yardId');
    const yesterdaysRanchesGroupedData = groupBy(groupedByDate[yesterdaysDataKey], 'ranchId');

    const yardsData = Object.keys(todaysGroupedData).reduce(mapData(todaysGroupedData, yesterdaysGroupedData), []);
    const ranchesData = Object.keys(todaysRanchesGroupedData).reduce(
        mapData(todaysRanchesGroupedData, yesterdaysRanchesGroupedData, true),
        []
    );

    return [...yardsData, ...ranchesData];
};

const getGroupedWorkspaceData = (data, yards, ranches, bhomes, workspaceActions, search, filter, sort, gridData) => {
    const yardsMap = new Map(yards.map(yard => [yard.id, yard]));
    const ranchesMap = new Map(ranches.map(ranch => [ranch.id, ranch]));

    const getHiveCategories = bhomesList => {
        const categories = {
            green: 0,
            yellow: 0,
            red: 0,
            grey: 0,
        };

        const gridDataByBhome = keyBy(gridData, 'id');

        bhomesList.forEach(bhome => {
            const gridItem = gridDataByBhome[bhome.id];
            if (gridItem) {
                if (gridItem.markedHives) {
                    gridItem.markedHives.forEach(({ assessment }) => {
                        const category = HIVE_CATEGORY_COLORS[assessment] || 'grey';
                        categories[category] += 1;
                    });
                } else {
                    categories.grey += DEFAULT_HIVE_CONFIGURATION;
                }
            }
        });

        return Object.entries(categories).map(([name, value]) => ({ name, value }));
    };

    const processItem = (item, isRanch) => {
        const entity = isRanch ? ranchesMap.get(item.id) : yardsMap.get(item.id);
        const actionsKey = isRanch ? 'ranch_id' : 'yard_id';
        const actionsFiltered = workspaceActions.filter(action => action[actionsKey] === item.id);
        const entityBhomes = isRanch ? entity?.locations?.flatMap(loc => loc.bhomes || []) || [] : entity?.bhomes || [];

        const nonOperationalBhomesAmount = entityBhomes.reduce((acc, entityBhome) => {
            const bhomesData = bhomes?.find(bhome => bhome.id === entityBhome.id);
            return bhomesData?.operational_state === constants.BHOME_OPERATIONAL_STATES.NON_OPERATIONAL ? acc + 1 : acc;
        }, 0);

        const totalAmountOfEmptyBroodHives = gridData?.reduce(
            (acc, gridItem) =>
                gridItem[isRanch ? 'ranchId' : 'yardId'] === item.id
                    ? acc + (gridItem.totalAmountOfEmptyBroodHives || 0)
                    : acc,
            0
        );

        const totalHives =
            nonOperationalBhomesAmount * DEFAULT_HIVE_CONFIGURATION +
            entityBhomes.reduce((acc, bhome) => acc + (bhome.hivesAmount || 0), 0);

        return {
            ...item,
            name: entity?.name ?? item.name,
            lat: entity?.lat,
            lng: entity?.lng,
            workspaceActions: actionsFiltered,
            nonOperationalBhomesAmount,
            bhomes: entityBhomes,
            totalAmountOfEmptyBroodHives,
            totalHives,
            hiveCategories: getHiveCategories(entityBhomes),
        };
    };

    const combinedData = data.map(item => processItem(item, item.isRanch));
    return combinedData.filter(filterYards(filter)).toSorted((a, b) => sortYards(a, b, sort));
};

export const getYardGroupedData = createSelector(
    [
        getWorkspaceData,
        getYards,
        getWorkspaceRanches,
        getBhomes,
        getWorkspaceActions,
        getWorkspaceSearch,
        getWorkspaceFilter,
        getWorkspaceSort,
        getGridData,
    ],
    getGroupedWorkspaceData
);

export const getFilterNumber = filter => {
    const keysToCheck = [
        'regions',
        'syrupTank',
        'larvae',
        averagesValues.EMPTY_FRAMES_AVERAGE,
        averagesValues.BEE_FRAMES_AVERAGE,
        averagesValues.HONEY_FRAMES_AVERAGE,
        averagesValues.BROOD_FRAMES_AVERAGE,
        actionsValues.FEED_ACTION,
        actionsValues.VISIT_ACTION,
        actionsValues.TRANSPORT_ACTION,
    ];

    return Object.keys(filter).reduce((acc, key) => {
        if (key === 'regions' && filter[key]?.length) {
            return acc + filter[key].length;
        }

        if (['syrupTank', 'larvae'].includes(key) && (filter[key].min > 0 || filter[key].max < 100)) {
            return acc + 1;
        }

        if (['activeOnly'].includes(key) && filter[key]) {
            return acc + 1;
        }

        if (keysToCheck.slice(5).includes(key) && filter[key].value) {
            return acc + 1;
        }

        if (
            (key === actionsValues.VISIT_ACTION ||
                key === actionsValues.FEED_ACTION ||
                key === actionsValues.TRANSPORT_ACTION) &&
            (filter[key].activeNow || filter[key].pastWeek || filter[key].moreThanAWeek)
        ) {
            return acc + 1;
        }

        return acc;
    }, 0);
};
