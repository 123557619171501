import React, { forwardRef } from 'react';

const Gallon = forwardRef(({ ...rest }, ref) => (
  <svg
    ref={ref}
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.77654 0.800244C1.77654 0.635495 1.91009 0.501939 2.07484 0.501939H3.93925C4.104 0.501939 4.23755 0.635495 4.23755 0.800244V1.7057H4.50512L5.03245 1.17837C5.17231 1.03851 5.362 0.95994 5.55979 0.95994H8.35749C8.93412 0.95994 9.40156 1.42739 9.40156 2.00401V10.2074C9.40156 10.784 8.93412 11.2515 8.35749 11.2515H1.64563C1.06901 11.2515 0.601562 10.784 0.601562 10.2074V2.84244C0.601562 2.70399 0.656562 2.57121 0.754463 2.47331L1.36917 1.8586C1.46707 1.7607 1.59985 1.7057 1.7383 1.7057H1.77654V0.800244ZM2.37315 1.7057H3.64094V1.09855H2.37315V1.7057ZM1.76919 2.30231L1.19817 2.87333V7.90489C2.60542 7.63368 3.53953 7.52948 5.08521 7.98104L5.09241 7.98314L5.09949 7.9856C5.94548 8.27962 6.55977 8.41726 7.13456 8.41757C7.65602 8.41785 8.16518 8.30505 8.80495 8.06339V2.00401C8.80495 1.75688 8.60462 1.55655 8.35749 1.55655H5.55979C5.52023 1.55655 5.48229 1.57226 5.45432 1.60024L4.90514 2.14941C4.80724 2.24731 4.67446 2.30231 4.53601 2.30231H1.76919ZM8.80495 8.6983C8.212 8.90578 7.68645 9.01448 7.13424 9.01418C6.46376 9.01381 5.77819 8.85267 4.91081 8.55164C3.46332 8.12989 2.63314 8.23251 1.19817 8.51264V10.2074C1.19817 10.4545 1.39851 10.6549 1.64563 10.6549H8.35749C8.60462 10.6549 8.80495 10.4545 8.80495 10.2074V8.6983ZM5.64292 2.17143C5.69924 2.11215 5.77742 2.07858 5.85919 2.07858H7.98461C8.14936 2.07858 8.28292 2.21214 8.28292 2.37689V3.49553C8.28292 3.66028 8.14936 3.79384 7.98461 3.79384H5.15071C4.98597 3.79384 4.85241 3.66028 4.85241 3.49553V3.12265C4.85241 3.04618 4.88178 2.97264 4.93444 2.9172L5.64292 2.17143ZM5.98726 2.67519L5.49132 3.19723H7.68631V2.67519H5.98726Z"
      fill="#616161"
    />
  </svg>
));

Gallon.displayName = 'Gallon';

export default Gallon;
