import dayjs from 'dayjs';
import { framesUtils } from '@beewise/utils';
import { inRange } from 'lodash-es';
import constants from 'appConstants';

export const getLatestTimestamp = timestamps =>
    timestamps.reduce((latest, current) => (current > latest ? current : latest), 0);

export const findHiveById = (hives, hiveId) => {
    const hive = [...hives[constants.STATIONS.A], ...hives[constants.STATIONS.B]].find(h => h.id === hiveId);
    if (!hive) {
        return null;
    }
    const feedTimestamps = hive.frames.map(frame => frame?.sideA?.feedTimestamp).filter(Boolean);
    const scanTimestamps = hive.frames
        .flatMap(frame => [frame?.sideA?.scanTimestamp, frame?.sideB?.scanTimestamp])
        .filter(Boolean);
    return {
        hive,
        stationName: hive.station,
        index: hive.index,
        latestFeedTimestamp: getLatestTimestamp(feedTimestamps),
        latestScanTimestamp: getLatestTimestamp(scanTimestamps),
    };
};

const getTypeFramesWithinHive = ({ frames, hive, type = constants.FRAME_TYPES.COMB_FRAME }) =>
    frames?.filter(frame => inRange(frame?.place?.position?.x || 0, hive?.from, hive?.to) && frame?.type === type);

const getFramesScannedState = ({ frames }) =>
    frames?.reduce(
        (acc, frame) => {
            acc.isSomeFramesUnscanned =
                acc.isSomeFramesUnscanned || !frame?.sideA?.scanTimestamp || !frame?.sideB?.scanTimestamp;
            acc.isSomeFramesUncounted =
                acc.isSomeFramesUncounted ||
                !frame?.sideA?.beeCount?.scanTimestamp ||
                !frame?.sideB?.beeCount?.scanTimestamp;
            return acc;
        },
        {
            isSomeFramesUnscanned: false,
            isSomeFramesUncounted: false,
        }
    ) || {
        isSomeFramesUnscanned: false,
        isSomeFramesUncounted: false,
    };

const getBroodAndPopulatedFrames = ({ hivesWithFrames, appSettings }) => {
    const broodFrameThreshold = appSettings?.broodFrameThreshold ?? 0;
    const beePopulationThreshold = appSettings?.beePopulationThreshold ?? 0;
    const honeyFrameThreshold = appSettings?.honeyFrameThreshold ?? 0;

    return hivesWithFrames.reduce(
        (acc, hive) => {
            hive.forEach(frame => {
                if (framesUtils?.isBeeFrame({ frame, threshold: beePopulationThreshold })) {
                    acc.populated += 1;
                }
                if (framesUtils?.isBroodFrame({ frame, threshold: broodFrameThreshold })) {
                    acc.brood += 1;
                }
                if (framesUtils?.isHoneyFrame({ frame, threshold: honeyFrameThreshold })) {
                    acc.honey += 1;
                }
                if (!framesUtils?.getElementSum(frame, 'honey') && !framesUtils?.getElementSum(frame, 'brood')) {
                    acc.empty += 1;
                }
            });
            return acc;
        },
        {
            brood: 0,
            populated: 0,
            honey: 0,
            empty: 0,
        }
    );
};

export const getTotalAmountOfFrame = ({ hive, appSettings, calculateFullyScannedOnly = true }) => {
    const acc = {
        totalBrood: 0,
        totalHoney: 0,
        totalEmpty: 0,
        totalPopulation: 0,
    };

    const combFramesInCurrentHive = getTypeFramesWithinHive({ frames: hive?.frames, hive });

    if (calculateFullyScannedOnly) {
        const isAllFramesUnscanned = combFramesInCurrentHive?.every(
            frame =>
                !frame?.sideA?.scanTimestamp &&
                !frame?.sideB?.scanTimestamp &&
                !frame?.sideA?.beeCount?.scanTimestamp &&
                !frame?.sideB?.beeCount?.scanTimestamp
        );

        if (isAllFramesUnscanned) {
            return acc;
        }
    }

    const { isSomeFramesUnscanned, isSomeFramesUncounted } = getFramesScannedState({
        frames: combFramesInCurrentHive,
    });

    if (isSomeFramesUnscanned && isSomeFramesUncounted && calculateFullyScannedOnly) {
        return acc;
    }

    const { brood, populated, honey, empty } = getBroodAndPopulatedFrames({
        hivesWithFrames: [combFramesInCurrentHive],
        appSettings,
    });

    acc.totalBrood += isSomeFramesUnscanned && calculateFullyScannedOnly ? 0 : brood;
    acc.totalHoney += isSomeFramesUnscanned && calculateFullyScannedOnly ? 0 : honey;
    acc.totalEmpty += isSomeFramesUnscanned && calculateFullyScannedOnly ? 0 : empty;
    acc.totalPopulation += isSomeFramesUncounted && calculateFullyScannedOnly ? 0 : populated;
    acc.totalAmountOfEmptyBroodHives += brood && calculateFullyScannedOnly ? 1 : 0;

    return acc;
};

export const formatTimestamp = timestamp => (timestamp ? dayjs(timestamp).format('D MMM') : 'N/A');
