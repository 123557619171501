import dayjs from 'dayjs';
import { useRef, useState, useEffect, useMemo } from 'react';
import Supercluster from 'supercluster';
import { isEqual, groupBy, keyBy } from 'lodash-es';
import constants from 'appConstants';

const EMPTY_ARRAY = [];

const LABEL_OFFSET = 0.02;

const getCluster = markers =>
    markers.map(marker => ({
        type: 'Feature',
        properties: { cluster: false, crimeId: marker.id },
        marker,
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(marker.lng), parseFloat(marker.lat)],
        },
    }));

// created own hook based on use-clusters lib does to performance issue https://github.com/leighhalliday/use-supercluster/issues/77
const useSuperCluster = ({ points, bounds, zoom, options }) => {
    const superclusterRef = useRef();
    const pointsRef = useRef();
    const prevClusters = useRef(EMPTY_ARRAY);

    const [clusters, setClusters] = useState(EMPTY_ARRAY);
    const zoomInt = Math.round(zoom);

    useEffect(() => {
        const featurePoints = getCluster(points);

        if (
            !superclusterRef.current ||
            !isEqual(pointsRef.current, points) ||
            !isEqual(superclusterRef.current?.options, options)
        ) {
            superclusterRef.current = new Supercluster(options);
            superclusterRef.current.load(featurePoints);
        }

        if (bounds) {
            const newClusters = superclusterRef.current.getClusters(bounds, zoomInt);
            if (!isEqual(prevClusters.current, newClusters)) {
                setClusters(newClusters.length ? newClusters : EMPTY_ARRAY);
                prevClusters.current = newClusters;
            }
        }

        pointsRef.current = points;
    }, [points, bounds, zoomInt, options]);

    return { clusters, supercluster: superclusterRef.current };
};

const superClusterParams = {
    minZoom: 2,
    maxZoom: 10,
    radius: 120,
    minPoints: 1,
};

const getBhomeWithStatus = ({ bhome }) => ({
    bhome_ids: [bhome.id],
    lat: bhome?.gps?.latitude && Number(bhome?.gps?.latitude),
    lng: bhome?.gps?.latitude && Number(bhome?.gps?.longitude),
    gpsUpdateDate:
        bhome.gps?.timestamp &&
        typeof bhome.gps?.timestamp === 'number' &&
        dayjs.tz(bhome.gps.timestamp, bhome.gps.timezone).utc(),
    name: `Bhome ${bhome.id} GPS`,
    locationId: bhome.location_id,
    ranch_id: bhome.ranch_id,
    yard_id: bhome.yard_id,
});

const getBoundingBox = bhomes => {
    const lats = bhomes.map(bhome => bhome?.gps?.latitude);
    const lngs = bhomes.map(bhome => bhome?.gps?.longitude);

    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);

    return { minLat, maxLat, minLng, maxLng };
};

const getCenterOfBhomesBox = (bhomesBox, mapOptions) => {
    const centerLat = bhomesBox.minLat - LABEL_OFFSET / mapOptions.zoom;
    const centerLng = (bhomesBox.minLng + bhomesBox.maxLng) / 2;
    return { lat: centerLat, lng: centerLng };
};

const updateYardsWithBhomeCoordinates = (yards, bhomes, mapOptions) =>
    yards.map(yard => {
        const yardBhomes = bhomes
            .filter(bhome => yard?.bhomes?.some(item => item.id === bhome.id))
            .map(bhome => bhome.id);
        if (!yardBhomes.length) {
            return yard;
        }

        const bhomesBox = getBoundingBox(yardBhomes);
        const yardLabelCoordinates = getCenterOfBhomesBox(bhomesBox, mapOptions);

        return {
            ...yard,
            lat: yardLabelCoordinates.lat,
            lng: yardLabelCoordinates.lng,
        };
    });

const filterNoRanchDrops = bhome => !bhome.ranch_id && bhome.location_id;
const filterRanchDrops = bhome => bhome.ranch_id;

const getFilteredBhomes = ({ bhomes, filterFn, locationsById }) => {
    const groupedBhomesByLocation = groupBy(bhomes.filter(filterFn), 'location_id');
    return Object.entries(groupedBhomesByLocation).map(([locationId, ranchBhomes]) => {
        const location = locationsById[locationId];
        return {
            ...location,
            bhome_ids: ranchBhomes.map(bhome => bhome.id),
        };
    });
};

export default ({ bhomes, locations, yards, ranches, mapOptions }) => {
    const yardBhomes = useMemo(
        () =>
            bhomes
                .filter(bhome => bhome.yard_id && !bhome.location_id && bhome?.gps?.latitude && bhome?.gps?.latitude)
                .map(bhome => getBhomeWithStatus({ bhome })),
        [bhomes]
    );

    const notAssignedBhomes = useMemo(
        () =>
            bhomes
                .filter(bhome => !bhome.location_id && !bhome.yard_id && bhome?.gps?.latitude && bhome?.gps?.latitude)
                .map(bhome => getBhomeWithStatus({ bhome })),
        [bhomes]
    );

    const assignedBhomes = useMemo(() => {
        const locationsById = keyBy(locations, 'id');
        const otherAssignedBhomes = getFilteredBhomes({ bhomes, filterFn: filterNoRanchDrops, locationsById });
        const ranchBhomes = getFilteredBhomes({ bhomes, filterFn: filterRanchDrops, locationsById });
        return [...otherAssignedBhomes, ...ranchBhomes];
    }, [bhomes, locations]);

    const emptyLocations = useMemo(() => locations.filter(location => !location.bhome_ids?.length), [locations]);

    const noYardBhomes = useMemo(() => [...assignedBhomes, ...notAssignedBhomes], [assignedBhomes, notAssignedBhomes]);

    const totalBhomes = useMemo(
        () => [...yardBhomes, ...assignedBhomes, ...notAssignedBhomes],
        [assignedBhomes, notAssignedBhomes, yardBhomes]
    );

    const updatedYards = useMemo(
        () => updateYardsWithBhomeCoordinates(yards, bhomes, mapOptions),
        [yards, bhomes, mapOptions]
    );

    const { clusters: locationClusters, supercluster: locationSuperClusters } = useSuperCluster({
        points: emptyLocations,
        bounds: mapOptions.boundsCoords,
        zoom: mapOptions.zoom,
        options: superClusterParams,
    });

    const { clusters: bhomeClusters, supercluster: bhomeSuperClusters } = useSuperCluster({
        points: mapOptions.zoom > constants.SPLIT_ZOOM ? totalBhomes : noYardBhomes,
        bounds: mapOptions.boundsCoords,
        zoom: mapOptions.zoom,
        options: superClusterParams,
    });

    const { clusters: ranchClusters, supercluster: ranchSuperClusters } = useSuperCluster({
        points: ranches,
        bounds: mapOptions.boundsCoords,
        zoom: mapOptions.zoom,
        options: superClusterParams,
    });

    const { clusters: yardClusters, supercluster: yardSuperClusters } = useSuperCluster({
        points: updatedYards,
        bounds: mapOptions.boundsCoords,
        zoom: mapOptions.zoom,
        options: superClusterParams,
    });

    return {
        locationClusters,
        locationSuperClusters,
        bhomeClusters,
        bhomeSuperClusters,
        ranchClusters,
        ranchSuperClusters,
        yardClusters,
        yardSuperClusters,
    };
};
